import React from "react";
import styles from "./LowerMenu.module.scss";
import lightning from "src/modules/common/assets/lowerMenu/img/lightning.svg";
import crown from "src/modules/common/assets/lowerMenu/img/crown.svg";
import flagCheckered from "src/modules/common/assets/lowerMenu/img/flagCheckered.svg";
import user from "src/modules/common/assets/lowerMenu/img/user.svg";
import wallet from "src/modules/common/assets/lowerMenu/img/wallet.svg";
import cl from "classnames";
import { useLocation, useNavigate } from "react-router-dom";

const LowerMenu = () => {
	const location = useLocation();
	const navigate = useNavigate();

	return (
		<div className={styles.content}>
			<div className={styles.wContent}>
				<div
					className={cl(styles.case, {
						[styles.active]: location.pathname === "/",
					})}
					onClick={() => {
						navigate("/");
					}}
				>
					<img src={lightning} alt={""} />
					<div>Tasks</div>
				</div>
				<div
					className={cl(styles.case, {
						[styles.active]: location.pathname === "/leaderboard",
					})}
					onClick={() => {
						navigate("/leaderboard");
					}}
				>
					<img src={crown} alt={""} />
					<div>Leaderboard</div>
				</div>
				<div className={cl(styles.case, { [styles.active]: false })}>
					<img src={wallet} alt={""} />
					<div>Wallet</div>
				</div>
				<div
					className={cl(styles.case, {
						[styles.active]: location.pathname === "/profile",
					})}
					onClick={() => {
						navigate("/profile");
					}}
				>
					<img src={user} alt={""} />
					<div>Profile</div>
				</div>
				<div className={cl(styles.case, { [styles.active]: false })}>
					<img src={flagCheckered} alt={""} />
					<div>Squads</div>
				</div>
			</div>
		</div>
	);
};

export default LowerMenu;
