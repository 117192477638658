import React from "react";
import { AppRoutes } from "./app/routes/AppRoutes";

function App() {
	return (
		<div>
			<AppRoutes />
		</div>
	);
}

export default App;
