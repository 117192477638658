import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { CustomBrowserRouter } from "./app/routes/CustomBrowserRouter";
import { createBrowserHistory } from "history";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

export const history = createBrowserHistory();

root.render(
	<CustomBrowserRouter history={history}>
		<App />
	</CustomBrowserRouter>
);
