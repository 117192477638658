import React from "react";
import styles from "./LeaderboardPage.module.scss";
import LeaderboardUser from "../LeaderboardUser/LeaderboardUser";
import exAvatar from "src/modules/leaderboard/assets/imgs/exAvatar.png";

const LeaderboardPage = () => {
	return (
		<div className={styles.content}>
			<div className={styles.reward}>
				<div className={styles.rewardText}>Rewards will be distributed in</div>
				<div className={styles.time}>3d 12h 36m</div>
			</div>
			<div className={styles.title}>pepe leaders</div>
			<div className={styles.areaForLeaders}>
				<div className={styles.wColumnsName}>
					<div className={styles.columnsName}>
						<div>Place</div>
						<div>User</div>
						<div>Points</div>
						<div>Reward</div>
					</div>
				</div>
				<div className={styles.users}>
					{[...new Array(13)].map((el, i) => (
						<LeaderboardUser
							points={416784}
							place={i + 1}
							reward={15000}
							nickname={"careamon"}
							avatar={exAvatar}
						/>
					))}
				</div>
				<div className={styles.shadow}>
					<div>Check Full List</div>
				</div>
			</div>
		</div>
	);
};

export default LeaderboardPage;
