import React from "react";
import styles from "./TasksPage.module.scss";
import ProgressBar from "../ProgressBar/ProgressBar";
import GeneralTasks from "../GeneralTasks/GeneralTasks";
import DailyTasks from "../DailyTasks/DailyTasks";

const TasksPage = () => {
	return (
		<div className={styles.content}>
			<ProgressBar />
			<GeneralTasks />
			<DailyTasks />
		</div>
	);
};

export default TasksPage;
