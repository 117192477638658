import React from "react";
import styles from "./Shadows.module.scss";
import shadow1bg from "src/modules/common/assets/imgs/shadow1bg.svg";
import shadow2bg from "src/modules/common/assets/imgs/shadow2bg.svg";

const Shadows = () => {
	return (
		<div className={styles.content}>
			<img src={shadow1bg} alt={""} className={styles.shadow1} />
			<img src={shadow2bg} alt={""} className={styles.shadow2} />
		</div>
	);
};

export default Shadows;
