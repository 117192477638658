import React from "react";
import styles from "./Header.module.scss";

const Header = () => {
	return (
		<div className={styles.content}>
			<div className={styles.text}>NEXT GEN PEPE</div>

			<div className={styles.blocks}>
				<div className={styles.block2}></div>
				<div className={styles.block1}></div>
			</div>
			<div className={styles.shadow}></div>
		</div>
	);
};

export default Header;
