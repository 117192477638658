import React from "react";
import styles from "./ProgressBar.module.scss";
import arrow from "src/modules/tasksPage/assets/imgs/svg/arrow.svg";

const ProgressBar = () => {
	return (
		<div className={styles.content}>
			<div className={styles.descriptionProgressBar}>
				<div className={styles.complete}>
					<div>Completed</div>
					<img src={arrow} alt={""} />
				</div>
				<div className={styles.level}>
					<div>Level</div>
					<div className={styles.numbers}>1/5</div>
				</div>
			</div>
			<div className={styles.progressBar}>
				<div className={styles.progress}></div>
			</div>
		</div>
	);
};

export default ProgressBar;
