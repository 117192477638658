import React from "react";
import styles from "./ProfilePage.module.scss";
import warning from "src/modules/profile/assets/imgs/warning.svg";
import star from "src/modules/profile/assets/imgs/star.svg";
import goIcon from "src/modules/tasksPage/assets/imgs/svg/goIcon.svg";
import link from "src/modules/profile/assets/imgs/link.svg";
import users from "src/modules/profile/assets/imgs/users.svg";
import borderAvatar from "src/modules/profile/assets/imgs/borderAvatar.svg";
import exAvatar from "src/modules/leaderboard/assets/imgs/exAvatar.png";

const ProfilePage = () => {
	return (
		<div className={styles.content}>
			<div className={styles.avatar}>
				<div className={styles.wImgAvatar}>
					<img src={borderAvatar} alt={""} />
					<img src={exAvatar} alt={""} className={styles.imgAvatar} />
				</div>
				<div className={styles.nickname}>CRYPTONAUT</div>
				<div className={styles.idPlayer}>#6295</div>
			</div>
			<div className={styles.pointBalance}>
				<div className={styles.warning}>
					<div>Points Balance</div>
					<img src={warning} alt={""} />
				</div>
				<div className={styles.areaPoints}>
					<div className={styles.star}>
						<img src={star} alt={""} />
					</div>
					<div className={styles.points}>
						<div className={styles.textPoints}>Points</div>
						<div className={styles.numberOfPoints}>46,840</div>
					</div>
					<div className={styles.arrow}>
						<img src={goIcon} alt={""} />
					</div>
				</div>
			</div>
			<div className={styles.accountInfo}>
				<div className={styles.title}>Account Info</div>
				<div className={styles.referral}>
					<div className={styles.img}>
						<img src={link} alt={""} />
					</div>
					<div className={styles.name}>Referrals</div>
					<div>
						<img src={goIcon} alt={""} />
					</div>
				</div>
				<div className={styles.mySquad}>
					<div className={styles.img}>
						<img src={users} alt={""} />
					</div>
					<div className={styles.name}>My Squad</div>
					<div>
						<img src={goIcon} alt={""} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfilePage;
