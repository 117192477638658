import React, { useState } from "react";
import styles from "./DailyTasks.module.scss";
import arrow from "../../assets/imgs/svg/arrow.svg";
import cl from "classnames";
import Task from "../Task/Task";

const DailyTasks = () => {
	const [isHideTasks, setIsHideTasks] = useState(false);

	return (
		<div className={styles.content}>
			<div className={styles.title}>
				<div
					className={styles.hideButton}
					onClick={() => {
						setIsHideTasks(!isHideTasks);
					}}
				>
					<div>Daily Tasks</div>
					<img
						src={arrow}
						className={styles.arrow}
						alt={""}
						style={isHideTasks ? { rotate: "90deg" } : { rotate: "-90deg" }}
					/>
				</div>
				<div className={styles.timerReset}>
					<div className={styles.textTimer}>Resets in</div>
					<div className={styles.timer}>01:18:33</div>
				</div>
			</div>
			<div
				className={cl(styles.areaForTasks, { [styles.active]: isHideTasks })}
			>
				<Task
					isDone={false}
					points={100}
					task={
						<>
							<div>Like 3 our today’s posts</div>
						</>
					}
				/>
				<Task
					isDone={false}
					points={190}
					task={
						<>
							<div>Comment on 3 our today’s posts</div>
						</>
					}
				/>
				<Task
					isDone={false}
					points={190}
					task={
						<>
							<div>Share 3 our today’s posts</div>
						</>
					}
				/>
			</div>
		</div>
	);
};

export default DailyTasks;
