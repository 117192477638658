import React, { useState } from "react";
import styles from "./GeneralTasks.module.scss";
import arrow from "src/modules/tasksPage/assets/imgs/svg/arrow.svg";
import Task from "../Task/Task";
import twitter from "../../assets/imgTasks/twitter.svg";
import telegramm from "src/modules/tasksPage/assets/imgTasks/telegramm.svg";
import cl from "classnames";

const GeneralTasks = () => {
	const [isHideTasks, setIsHideTasks] = useState(false);

	return (
		<div className={styles.content}>
			<div
				className={styles.hideButton}
				onClick={() => {
					setIsHideTasks(!isHideTasks);
				}}
			>
				<div>General Tasks</div>
				<img
					src={arrow}
					className={styles.arrow}
					alt={""}
					style={isHideTasks ? { rotate: "90deg" } : { rotate: "-90deg" }}
				/>
			</div>
			<div
				className={cl(styles.areaForTasks, { [styles.active]: isHideTasks })}
			>
				<Task
					isDone={true}
					points={50}
					task={
						<>
							<div>Subscribe to our</div>
							<img src={twitter} alt={""} />
						</>
					}
				/>
				<Task
					isDone={false}
					points={75}
					task={
						<>
							<div>Subscribe to our</div>
							<img src={telegramm} alt={""} />
						</>
					}
				/>
			</div>
		</div>
	);
};

export default GeneralTasks;
