import React from "react";
import styles from "./LeaderboardUser.module.scss";

const LeaderboardUser: React.FC<{
	place: number;
	avatar: string;
	nickname: string;
	points: number;
	reward: number;
}> = ({ place, avatar, nickname, points, reward }) => {
	return (
		<div className={styles.content}>
			<div className={styles.place}>{place}</div>
			<div className={styles.avatar}>
				<img src={avatar} alt={""} />
			</div>
			<div className={styles.nickname}>{nickname}</div>
			<div className={styles.points}>
				{new Intl.NumberFormat("en").format(points)}
			</div>
			<div className={styles.reward}>{reward} PEPE</div>
		</div>
	);
};

export default LeaderboardUser;
