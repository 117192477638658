import { useContext, useEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import TasksPage from "../../modules/tasksPage/ui/TasksPage/TasksPage";
import LowerMenu from "../../modules/common/ui/LowerMenu/LowerMenu";
import MainGrid from "../../modules/common/ui/MainGrid/MainGrid";
import LeaderboardPage from "../../modules/leaderboard/ui/LeaderboardPage/LeaderboardPage";
import Header from "../../modules/common/ui/Header/Header";
import ProfilePage from "../../modules/profile/ui/ProfilePage/ProfilePage";
import Shadows from "../../modules/common/ui/Shadows/Shadows";

export const AppRoutes = () => {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<MainGrid>
						<Header />
						<Outlet />
						<LowerMenu />
						<Shadows />
					</MainGrid>
				}
			>
				<Route path={"/"} element={<TasksPage />} />
				<Route path={"/leaderboard"} element={<LeaderboardPage />} />
				<Route path={"/profile"} element={<ProfilePage />} />
			</Route>
		</Routes>
	);
};
