import React from "react";
import styles from "./Task.module.scss";
import doneIcon from "src/modules/tasksPage/assets/imgs/svg/doneIcon.svg";
import goIcon from "src/modules/tasksPage/assets/imgs/svg/goIcon.svg";

const Task: React.FC<{ isDone: boolean; points: number; task: any }> = ({
	isDone,
	points,
	task,
}) => {
	return (
		<div
			className={styles.content}
			style={isDone ? { background: "#20371F" } : {}}
		>
			<div className={styles.areaPoints}>
				<div className={styles.number}>{points}</div>
				<div className={styles.text}>points</div>
			</div>
			<div className={styles.taskName}>{task}</div>
			<div className={styles.state}>
				<img src={isDone ? doneIcon : goIcon} alt={""} />
			</div>
		</div>
	);
};

export default Task;
